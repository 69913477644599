import { GridColDef } from '@mui/x-data-grid-pro'

export const getColumns = prismic => {
  const columns: GridColDef[] = [
    {
      field: 'rowId',
      headerName: 'Row Id',
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'treeLevel',
      headerName: prismic.level,
      minWidth: 200,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'legacyAssociateId',
      headerName: prismic.id,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'name',
      headerName: prismic.name,
      minWidth: 200,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'associateType',
      headerName: prismic.customer_type,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'associateStatus',
      headerName: prismic.status,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'currentOvRankName',
      headerName: prismic.current_ov_rank,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'highestOvRankName',
      headerName: prismic.highest_ov_rank,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'phone',
      headerName: prismic.phone,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'email',
      headerName: prismic.email,
      minWidth: 200,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'signupDate',
      headerName: prismic.start_date,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'enrollerName',
      headerName: prismic.sponsor,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'pv',
      headerName: prismic.personal_volume,
      minWidth: 200,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
  ]
  return columns
}
